<template>
  <div class="container">
    <b-card v-if="verify_status === 'PENDING'" class="verify-bankid-body mb-5" style="margin: auto; border: 1px solid #e1e1e1;">
      <h3 class="text-center mt-4 mb-4">{{$t('MEMBER.VERIFY_WITH_BANKID')}}</h3>
      <p>Innan er ansökan kan behandlas behöver vi verifiera ditt personummer. Det personnummer som fylldes i måste överrensstämma med det personummer som signeras via BankID.</p>
      <form>

        <div v-if="show_qr" id="qr-container">
          <img style="margin:auto; width: 60%;" align-h="center" :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" class="img-fluid mb-8" alt="bankid" />

          
          <p>{{ $t('MEMBER.START_BANKID_QR') }}</p>

          <img :src="qr_url" style="margin:auto;" align-h="center" />
          
        </div>
        
        <div v-if="show_options" id="qr-container">
          <img style="margin:auto; width: 60%;" align-h="center" :src="require('@/assets/images/BankID_MasterBrand/BankID_logo_black.png')" class="img-fluid mb-8" alt="bankid" />

          <b-button style="margin: auto; width:80%;" variant="primary" @click.prevent="mobile_bankid_clicked">{{ $t('ACCOUNT.MOBILE_BANKID') }}</b-button>
          
          <div v-if="show_open_bankid_spinner" class="d-flex justify-content-center mb-8 mt-8">
            <b-spinner label="Loading..."></b-spinner>
          </div>
          <b-button v-else style="margin: auto; margin-top: 20px; width:80%;" variant="outline-primary" @click.prevent="open_bankid_clicked">{{ $t('ACCOUNT.OPEN_BANKID') }}</b-button>
        </div>

        
        <div v-if="show_user_not_found" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text"><p>{{$t('ACCOUNT.NO_USER')}}</p><p>{{$t('ACCOUNT.NO_USER_INFO')}}</p></div>
        </div>
        
        <div v-if="show_no_permissions" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
          <div class="alert-icon"><i class="flaticon-warning"></i></div>
          <div class="alert-text"><p>{{$t('ACCOUNT.NO_ACCESS_INFO1')}}</p><p>{{$t('ACCOUNT.NO_ACCESS_INFO2')}}</p></div>
        </div>
        
        <b-alert
          :show="showWarning"
          variant="danger"
          dismissible
          @dismissed="showWarning = false"
          fade
          >{{$t('ACCOUNT.BANKID_ERROR')}}</b-alert
        >

        <b-alert
          :show="show_mismatch"
          variant="danger"
          dismissible
          @dismissed="show_mismatch = false"
          fade
          >{{$t('MEMBER.BANKID_VERIFY_PERSONNR_MISMATCH')}}</b-alert
        >

      </form>
    </b-card>

    <b-card v-else-if="verify_status === 'DONE'">

      <div v-if="create_status === 'PENDING'" class="d-flex justify-content-center mt-3" >
        <b-spinner label="Loading..."></b-spinner>
      </div>

      <b-card v-else-if="create_status === 'CREATED'">
        <b-card :title="$t('PUBLIC.REGISTRATION.MEMBERSHIP_PENDING')" hide-footer class="mb-2 mt-2 ">
          <b-alert variant="success" show
            >{{$t('PUBLIC.REGISTRATION.MEMBERSHIP_PENDING_INFO')}}</b-alert
          >
        </b-card>
      </b-card>

      <b-card v-else-if="create_status === 'ERROR'">
        <b-card :title="$t('PUBLIC.REGISTRATION.MEMBERSHIP_ERROR')" hide-footer class="mb-2 mt-2 ">
          <b-alert variant="danger" show
            >{{$t('PUBLIC.REGISTRATION.MEMBERSHIP_ERROR_MESSAGE')}}</b-alert
          >
        </b-card>
      </b-card>
    </b-card>

    <b-card v-else-if="verify_status === 'RETURNED'">
      <h4>Väntar på svar...</h4>

      <p>Vi inväntar svar från BankID tjänsten.</p>
    </b-card>

    <b-card v-else-if="verify_status === 'ERROR'">
      <h4>Ett fel uppstod</h4>

      <p>BankID signering resulterade i ett fel. Säkerställ att ni registrerade er med samma personnummer som er BankID använder.</p>
    </b-card>

    <div v-for="(item, index) in errors" :key="index" class="alert alert-custom alert-notice alert-light-danger fade show" role="alert">
      <div class="alert-icon"><i class="flaticon-warning"></i></div>
      <div class="alert-text"><p>ERROR: {{ item }}</p></div>  
    </div>

  </div>
  
</template>

<style lang="scss" scoped>

.container {
  margin: auto;
}

#qr-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.verify-bankid-body {
  max-width: 500px;
  width: 100%;
  .login-form {
    padding: 2rem 1.5rem 2rem 1.5rem;
    .btn-login {
      font-size: 1.2rem;
      font-weight: 500;
      padding: 1rem 3rem;
      background-color: #5d78ff;
      border-color: #5d78ff;
      &:hover {
        background-color: #3758ff;
        border-color: #2a4eff;
      }
    }
  }
}
</style>

<script>
import { setKeyValue, getKeyValue } from '@/core/services/simpleStore';
import { mapState } from 'vuex';
import { LOGIN } from '@/core/services/store/auth.module';
import { SET_USER_ID, SET_COMPANY_ID } from '@/core/services/store/common.module';
import { SET_IS_SUPERADMIN, SET_IS_THS, LOGOUT } from '@/core/services/store/auth.module';

import axios from 'axios';

import store from '@/core/services/store';
import { hasAccessToPageForCurrentCompany, initFromLogin, getAnyPageWithAccess, clearCurrentCompanyId } from '@/core/services/companyAccess';
import { is_mobile } from '@/core/services/utils';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { requiredUnless } from 'vuelidate/lib/validators';
import { get_base_url, downloadWithAxios } from '@/core/services/fileDownload';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

import { isPlugin } from "@/core/services/messageParent";
import messageParent from '@/core/services/messageParent';

export default {
  mixins: [toasts],
  name: 'verify-member-bankid',

  data() {
    return {
      successful_create: false,
      errors: [],

      verify_status: 'PENDING',

      create_status: 'PENDING',

      registration_form: null,

      order_ref: null,
      use_local_app: false,
      show_mismatch: false,

      
      personnr: null,
      form: null,
      post_register_url: null,


      debug: false,
      collect_data: null,


      show_options: true,
      show_qr: false,
      show_user_not_found: false,
      show_open_bankid_spinner: false,

      interval_id: null,
      qr_url: null,
      showWarning: false,
      show_no_permissions: false
    };
  },
  /*
  BankId flow for samfund:

  RegisterWizard store its form in the 'form'. We also store away the personnr and post_register_url.

  RegisterWizard will forward to this page using this.$router.push({ name: 'bankid-verify-member' });

  All the form data will be retrieved using getKeyValue. However we can not rely on the form data beyond this point
  as the bankid app will open in a new window.

  We will post
  const res = await axios.post(`/bankid/member/verify?qr=${qr ? 'true' : 'false'}&token=${this.bankid_auth_token}`, { form: form, personnr: personnr, post_register_url: post_register_url });

  axios.post(`/bankid/member/collect/token/personnr`
  will collect the data of the member, and we will also get the registration form data

  when using the open app locally option, we return to this page, but we cant rely on getKeyValue

  
  */
  
  async mounted() {



    try {
      this.debug = this.$route.query.debug !== undefined ? true : false;
      this.token = this.$route.params.token;
      
      if (this.token) {
        console.log('TOKEN IS SET', this.token);

        const order_ref = this.$route.query.order_ref;

        this.verify_status = 'RETURNED';

        this.collect_from_local_app_return(this.token, order_ref);
      }
      else {

        // this is when we are going here for the first time
        // directly from the RegisterWizard. The most important is that we generate a token
        // that we can use to communicate with the Api and store away the registration form.

        this.bankid_auth_token = this.randomstr(32);

        console.log('bankid token used: ' + this.bankid_auth_token);

        const form = getKeyValue('form');
        this.personnr = getKeyValue('personnr');
        this.post_register_url = getKeyValue('post_register_url');

        if (!form) {
          console.error('form is empty');
          return;
        }

        console.log('form is set', form);

        this.form = JSON.parse(form);
        
        if (!this.form) {
          console.error('invalid undefined form');
          return;
        }
        

        if (!this.personnr) {
          console.error('no personnr given, impossible to proceed');
          return;
        }

        if (this.debug) {
          console.log('DEBUG ENABLED!');
        }

        if (this.interval_id) {
          clearInterval(this.interval_id);
        }

        console.log('Needed data personnr: ', this.personnr);
        console.log('Needed data post_register_url: ', this.post_register_url);
        console.log('Needed data form: ', this.form);
      }

    }
    catch (err) {
      console.error('VerifyWithBankIdPage mounted error ', err);
    }

  },

  destroyed() {
    clearInterval(this.interval_id);
  },
  
  methods: {

    async collect_from_local_app_return(token, order_ref) {
      try {
        const res = await axios.post(`/bankid/member/collect/token/personnr`, { token: token, order_ref: order_ref });

        if (res.status === 200) {
          this.verify_status = 'DONE';
          
          const post_register_url = res.data.form.post_register_url;

          this.register_member(res.data.form.form, post_register_url);

          return;
        }
        else {
          console.log('invalid return status', res.status);
        }
      }
      catch (err) {
        console.error('collect_from_local_app_return error', err);
      }
    },

    start_bankid_polling() {

      if (this.interval_id) {
        clearInterval(this.interval_id);
      }

      this.interval_id = setInterval(async () => {
        this.qr_url = await this.get_qr_bankid();
        this.collect_data = await this.get_bankid_collect();
      }, 4000);
    },

    randomstr(length) {
      let result = '';
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      const charactersLength = characters.length;

      for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
      }

      return result;
    },

    async mobile_bankid_clicked() {

      try {
        this.show_qr = true;
        this.show_options = false;
        this.use_local_app = false;

        this.qr_url = await this.get_qr_bankid();

        this.start_bankid_polling();
      }
      catch (err) {
        console.error('mobile_bankid_clicked', err);
      }

    },

    forward_to_bankid(auto_start_token, return_url) {
      console.log('forward_to_bankid', auto_start_token, return_url);
      try {
        location.href = `bankid:///?autostarttoken=${auto_start_token}&redirect=${return_url}`;
      }
      catch (err) {
        location.href = `https://app.bankid.com/?autostarttoken=${auto_start_token}&redirect=${return_url}`;
      }

    },

    async open_bankid_clicked() {
      try {
        this.show_open_bankid_spinner = true;

        const data = await this.get_bankid_verify(false);

        if (data) {

          console.log('data set from get_bankid_verify ', data);

          const return_url = get_base_url() + `/bankid-verify-return/${this.bankid_auth_token}?order_ref=${data.order_ref}`;

          console.log('bankid return url: ', return_url);

          setKeyValue('bankid_order_ref', data.order_ref);

          this.use_local_app = true;
          this.order_ref = data.order_ref;

          console.log('forwarding to bankid app', data.auto_start_token);

          this.forward_to_bankid(data.auto_start_token, return_url);
          
          return;
        }

        console.error('bankid error data not set');

        this.show_open_bankid_spinner = false;
      }
      catch (err) {
        console.error(err);

        this.show_open_bankid_spinner = false;
      }
      
    },

    async get_qr_bankid() {
      try {
        const data = await this.get_bankid_verify(true);

        if (data) {
          return data.qr_url;
        }
        
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    async get_bankid_verify(qr) {
      try {
        const res = await axios.post(`/bankid/member/verify?qr=${qr ? 'true' : 'false'}&token=${this.bankid_auth_token}`, 
          { 
            form: this.form,
            post_register_url: this.post_register_url,
            personnr: this.personnr
          });

        if (res.status === 200) {
          return res.data;
        }
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },


    async get_bankid_collect() {
      try {
        
        const res = await axios.post(`/bankid/member/collect/token/personnr`, { token: this.bankid_auth_token });

        const form = res.data.form.form;
        const post_register_url = res.data.form.post_register_url;
        
        if (res.status === 200) {

          if (res.data.status === 'verified') {
            console.log('verification success');

            this.verify_status = 'DONE';

            if (this.interval_id) {
              clearInterval(this.interval_id);
            }

            this.register_member(form, post_register_url);

            return res.data;
          }
          
        }
        else if (res.status === 409 || res.status === 406) {
          this.verify_status = 'ERROR';
        }
        
      }
      catch (err) {
        console.error(err);
      }

      return null;
    },

    async register_member(form, post_register_url) {
      
      try {
        this.create_status = 'PENDING';

        const res = await axios.post('/member/public', form);

        if (res.status === 201) {

          this.create_status = 'CREATED';

          messageParent(
            JSON.stringify({
              register: true,
              member_id: res.data.member_id,
              token: res.data.token
            })
          );

          const order = res.data.order;
          const member_id = res.data.member_id;

          if (order) {
            switch (order.expected_action) {
              case 'DINTERO_PAYMENT': {
                this.$router.push(`/payment/${order.shop_order_id}/${order.dintero_id}/${order.token}`);
                break;
              }
              case 'STRIPE_PAYMENT': {
                /// send to stripe payment with register as type
                this.$router.push(`/stripe-payment/${member_id}?client_secret=${order.stripe_client_secret}&type=register&token=${order.token}`);
                break;
              }
              case 'NO_PAYMENT': {
                if (post_register_url) {
                  let url = post_register_url;

                  // add http:// if not present

                  if (!url.match(/^https?:\/\//)) {
                    url = 'http://' + url;
                  }
                  
                  messageParent(JSON.stringify({ redirect: url }));
                  
                  if (!isPlugin()) {
                    window.location.href = url;
                  }
                } else {
                  this.successful_create = true;
                }
                break;
              }
              default: {
                /// STRIPE_INVOICE_PAYMENT
                /// INVOICE_PAYMENT
                /// NOT_DETERMINED
                /// UNKNOWN
                this.toastr(this.$t('COMMON.ERROR'), 'Not implemented payment option: ' + order.expected_action);
                break;
              }
            }
          }
          else {
            this.toastr('danger', this.$t('COMMON.ERROR'), 'No order was created');
          }

          
        } else if (res.status === 409) {
          this.create_status = 'ERROR';

          this.toastr('danger', this.$t('COMMON.ERROR'), 'E-mail finns redan registrerad');

          console.error('register_member status 409');
          
        } else {
          messageParent(JSON.stringify({ register: false }));
          
          this.create_status = 'ERROR';

          console.error('register_member status error', res.status, res.data);
        }
      }
      catch (err) {
        this.create_status = 'ERROR';
        console.error('register_member exception', err);
      }
    }


  },
  computed: {
    ...mapState({
      
    })
  }
};
</script>
